import { EventEmitter, Injectable, OnInit } from '@angular/core';
import { AuthenticationService } from 'clan-auth';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthorizationService {
  constructor(private _authService: AuthenticationService) {}
  public isAuthenticated: Subject<boolean> = new Subject<boolean>();
  public isAuthenticated$: Observable<boolean> = this.isAuthenticated.asObservable();
  public userUpdated = new EventEmitter<boolean>();
  private decodedToken;
  private roles$ = new Subject<any[]>();
  private _rolesObservable$ = this.roles$.asObservable();
  token;

  getDecodedToken() {
    if (!this.decodedToken) {
      this.decodedToken = this.parseJwt(this._authService.authToken);
    }
    return this.decodedToken;
  }

  setIsAuthenticated(isAuthenticated: boolean) {
    this.isAuthenticated.next(isAuthenticated);
  }

  getIsAuthenticated() {
    return this.isAuthenticated$;
  }

  parseJwt(token) {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  }
  setAllRoles(roles) {
    this.roles$.next(roles);
  }
  getAllRoles() {
    this.token = localStorage.getItem('swarm-auth-token');
    if (this.token) {
      const roles = this.parseJwt(this.token).realm_access.roles;
      this.setAllRoles(roles);
    }
    return this._rolesObservable$;
  }

  getId(){
    this.token = localStorage.getItem('swarm-auth-token');
    if (this.token) {
      return this.parseJwt(this.token).sub
    }
    return ""
  }

  logout() {
    this.decodedToken = undefined;
  }
}
